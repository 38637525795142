import React from "react"
import {
  ScGridRow,
  ScH1,
  ScText,
  ScH3,
  ScExternalLinkButton,
} from "./StyledComponents/elements"
import styled from "styled-components"

const DescriptionWrapper = styled.div`
  @media only screen and (max-width: 960px) {
  }
`
function PortalDescription({ title, p1 }) {
  return (
    <DescriptionWrapper>
      <ScGridRow>
        <ScH1>Portal In The Park</ScH1>
      </ScGridRow>
      <ScGridRow>
        <ScText fontSize="data-text">
          Casey’s new sing-a-long book combines rap music with hot contemporary
          pop creating an interactive musical mix that has yet to be heard. The
          music audio book/read offers a portal for children to embrace physical
          activity and mental balance through music and imagination. The
          holistic style story features down to earth, “slamming” dialogue and
          advice, coupled with catchy songs performed by father of hip hop
          Grandmaster Melle Mel, featuring pop icon Lady Gaga, including
          Fountain of Truth and World Family Tree.
        </ScText>
      </ScGridRow>
      <ScGridRow>
        <ScText fontSize="data-text">
          The story begins in Central Park and follows the hero through his
          magical adventure to a happier and healthier life. Casey introduces
          frightening little monsters, “Angroid,” “Fearoid,” and “Graboid” to
          the world’s next tech savvy, multi-tasking generation, and focuses on
          the battle against good and evil within. The result is feel-good
          entertainment for youngsters, filled with positive advice on living in
          the moment and overcoming negativity and bullying.
        </ScText>
      </ScGridRow>
      <ScGridRow>
        <ScText fontSize="data-text">
          Returning to the real world, he is a changed boy with new, healthier
          values, and a happier attitude. This is a fictional tale that is
          brought to life with music and songs that move children in a new
          direction combining music with literature, stimulating both right and
          left brain to help kids Read, Rap and Remember.
        </ScText>
      </ScGridRow>
      <ScGridRow>
        <ScText fontSize="data-text">
          NOTE: The audiobook consists of the full narration of the story plus
          seven original songs written and performed by Grandmaster Melle Mel,
          and two songs featuring Lady Gaga. This interactive Audiobook "reads"
          to the class, teacher, kids, or family members while they can all
          sing-a-long. It is geared to elementary school age children 5 to 9
          years of age.
        </ScText>
      </ScGridRow>
      <ScGridRow>
        <ScH3>Order on Amazon!</ScH3>
      </ScGridRow>
      <ScGridRow justifyContent="start">
        <ScExternalLinkButton
          style={{ marginRight: "20px" }}
          color="primary"
          href="https://www.amazon.com/Portal-Park-Songs-performed-Grandmaster/dp/1523353155/ref=tmm_pap_swatch_0?_encoding=UTF8&qid=1578193546&sr=8-1"
        >
          PAPERBACK
        </ScExternalLinkButton>
        <ScExternalLinkButton
          style={{ marginRight: "20px" }}
          color="primary"
          href="https://www.amazon.com/Portal-Park-Songs-performed-Grandmaster/dp/1523353155/ref=tmm_pap_swatch_0?_encoding=UTF8&qid=1578193546&sr=8-1"
        >
          HARDBACK
        </ScExternalLinkButton>
        <ScExternalLinkButton
          style={{ marginRight: "20px" }}
          color="primary"
          href="https://www.amazon.com/Portal-Park-Songs-performed-Grandmaster/dp/1523353155/ref=tmm_pap_swatch_0?_encoding=UTF8&qid=1578193546&sr=8-1"
        >
          AUDIO BOOK
        </ScExternalLinkButton>
      </ScGridRow>
    </DescriptionWrapper>
  )
}

export default PortalDescription
