import React from "react"
import BookTemplate from "../components/common/BookTemplate"
import PortalImg from "../components/common/PortalImg"
import PortalDescription from "../components/PortalDescription"
import SEO from "../components/seo"

function PortalInThePark() {
  return (
    <div>
      <SEO title="Portal in the Park" />
      <BookTemplate BookImg={PortalImg} BookDescription={PortalDescription} />
    </div>
  )
}

export default PortalInThePark
