import React from "react"
import {
  ScGridRow,
  ScGridCol,
  ScText,
  ScPageWrapper,
} from "../StyledComponents/elements"

function BookTemplate({ BookImg, BookDescription }) {
  return (
    <ScPageWrapper>
      <ScGridRow>
        <ScGridCol span={6}>
          <BookImg />
        </ScGridCol>
        <ScGridCol span={6}>
          <BookDescription />
        </ScGridCol>
      </ScGridRow>
    </ScPageWrapper>
  )
}

export default BookTemplate
